<template>
  <b-button-group style="margin: -2px -4px 0 0;">
    <b-button size="sm" :variant="variantSuccess" class="p-0 border-0"
              style="font-size: 12px; width: 40px" id="tt-acknowledge" @click="acknowledge">
      <b-icon-check/>
    </b-button>
    <b-tooltip target="tt-acknowledge" triggers="hover">Zur Kenntnis nehmen</b-tooltip>
    <b-button size="sm" variant="primary" class="p-0 border-0"
              style="font-size: 12px; width: 40px" id="tt-allowTemp" @click="allowTemp">
      <b-icon-clipboard-check/>
    </b-button>
    <b-tooltip target="tt-allowTemp" triggers="hover">Zur temporären Allowlist hinzufügen</b-tooltip>
  </b-button-group>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "VirusButtonGroup",
  methods: {
    acknowledge() {
      this.$emit('acknowledge')
    },
    allowTemp() {
      this.$emit('allow-temp')
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
