<template>
  <div>
    <b-row cols="2" class="entry-danger" @mouseenter="hover = true" @mouseleave="hover = false"
           :class="['virus-entry-danger', {rounded: !expand}]">
      <b-col cols="10" class="text-left pl-1">
        <span @click="expand = !expand" class="clickable virus-link">{{ virus.path }}</span>
      </b-col>
      <b-col cols="2" class="text-right pr-1">
        <div v-if="hover">
          <VirusButtonGroup @acknowledge="acknowledge" @allow-temp="allowTemp"/>
        </div>
        <div v-else class="virus-count">
          {{ virus.count }}x
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="expand" cols="12" class="virus-details">
        <b-row>
          <LastTimestamps :object="virus"/>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LastTimestamps from "@/components/lists/LastTimestamps";
import VirusButtonGroup from "@/components/lists/virusList/utility/VirusButtonGroup";

export default {
  name: "VirusEntry",
  components: {
    LastTimestamps,
    VirusButtonGroup
  },
  props: ['virus'],
  data() {
    return {
      hover: false,
      expand: false,
    }
  },
  methods: {
    acknowledge() {
      this.$emit('virus-modal', { virus: this.virus, type: 'acknowledge' })
    },
    allowTemp() {
      this.$emit('virus-modal', { virus: this.virus, type: 'allowTemp' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/VirusEntry.scss';
</style>
